import React, { useEffect } from 'react';
import '../css/ParallaxSection.css';

function ParallaxSection() {
  useEffect(() => {
    const handleScroll = () => {
      const parallax = document.querySelector('.parallax-section');
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * 0.7 + 'px'; // Adjust the multiplier for speed
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="parallax-section">
      <div className="parallax-content">
        <h1>Bestattungsinstitut Sonnenburg</h1>
        <p>Jörg Sonnenburg Familienbetrieb seit 1849</p>
      </div>
    </div>
  );
}

export default ParallaxSection;
