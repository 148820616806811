import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../css/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-info-centered">
          <h4 className="footer-title">Bestattungsinstitut Sonnenburg</h4>
          <div className="footer-info-block">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon"/>
            <div className="footer-text">
              <p>Jörg Sonnenburg</p>
              <p>Koppelsberg 1</p>
              <p>28790 Schwanewede</p>
              <p>(direkt am Kreisverkehr, Richtung Hinnebeck)</p>
            </div>
          </div>
          <div className="footer-info-block">
            <FontAwesomeIcon icon={faPhoneAlt} className="footer-icon" />
            <div className="footer-text">
              <p>Tel. 04209 1235</p>
            </div>
          </div>
          <div className="footer-info-block">
            <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />
            <div className="footer-text">
              <p>E-Mail: bestattungsinstitut.sonnenburg@t-online.de</p>
            </div>
          </div>         
        </div>
        <div className="footer-links">
          <ul>
            <li><a href="/faq">FAQ</a></li>
            <li><a href="/agb">AGB</a></li>
            <li><a href="/datenschutz">Datenschutz</a></li>
            <li><a href="/impressum">Impressum</a></li>
            <li><a href="/widerruf">Widerruf</a></li>
            <li><a href="/widerrufsformular">Widerrufsformular</a></li>
            <li><a href="/kontakt">Kontakt</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Bestattungsinstitut Sonnenburg. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
