import React from 'react';

function Widerruf() {
  return (
    <div>
      <h1>Widerruf</h1>
      <p>This is the Widerruf (Cancellation) page. You can update it with your cancellation policies.</p>
    </div>
  );
}

export default Widerruf;
