import React from 'react';

function Datenschutz() {
  return (
    <div>
      <h1>Datenschutz</h1>
      <p>This is the Datenschutz (Privacy Policy) page. You can update it with your privacy policy.</p>
    </div>
  );
}

export default Datenschutz;
