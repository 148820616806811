import React from 'react';

function Impressum() {
  return (
    <div>
      <h1>Impressum</h1>
      <p>This is the Impressum (Legal Notice) page. You can update it with your legal information.</p>
    </div>
  );
}

export default Impressum;
