import React from 'react';
import Navbar from '../components/Navbar';
import ParallaxSection from '../components/ParallaxSection';
import MainBody from '../components/MainBody';

function Home() {
  return (
    <div>
      <ParallaxSection />
      <Navbar />
      <MainBody />
      {/* Other content of the homepage */}
    </div>
  );
}

export default Home;
