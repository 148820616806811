import React, { useState } from 'react';
import '../css/Navbar.css'; // Adjust the path as necessary

function Navbar() {
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownToggle = (event, index) => {
    event.preventDefault(); // Prevent default link behavior
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <ul className="nav-menu">
          <li><a href="/" className="nav-link">Startseite</a></li>
          
          <li className={`dropdown ${openDropdown === 0 ? 'open' : ''}`}>
            <a href="#" className="nav-link dropdown-toggle" onClick={(e) => handleDropdownToggle(e, 0)}>
              Tradition
            </a>
            <ul className={`dropdown-menu ${openDropdown === 0 ? 'open' : ''}`}>
              <li><a href="/wie-es-1849-begann" className="dropdown-link">Wie es 1849 begann</a></li>
              <li><a href="/das-unternehmen-heute" className="dropdown-link">Das Unternehmen heute</a></li>
            </ul>
          </li>
          
          <li><a href="/team" className="nav-link">Team</a></li>
          
          <li className={`dropdown ${openDropdown === 1 ? 'open' : ''}`}>
            <a href="#" className="nav-link dropdown-toggle" onClick={(e) => handleDropdownToggle(e, 1)}>
              Leistungen
            </a>
            <ul className={`dropdown-menu ${openDropdown === 1 ? 'open' : ''}`}>
              <li><a href="/die-bestattung" className="dropdown-link">Die Bestattung</a></li>
              <li><a href="/kosten-einer-bestattung" className="dropdown-link">Kosten einer Bestattung</a></li>
            </ul>
          </li>
          
          <li className={`dropdown ${openDropdown === 2 ? 'open' : ''}`}>
            <a href="#" className="nav-link dropdown-toggle" onClick={(e) => handleDropdownToggle(e, 2)}>
              Bestattungsarten
            </a>
            <ul className={`dropdown-menu ${openDropdown === 2 ? 'open' : ''}`}>
              <li><a href="/anonym" className="dropdown-link">Anonym</a></li>
              <li><a href="/erde" className="dropdown-link">Erde</a></li>
              <li><a href="/rasengrab" className="dropdown-link">Rasengrab</a></li>
              <li><a href="/reihengrab" className="dropdown-link">Reihengrab</a></li>
              <li><a href="/see" className="dropdown-link">See</a></li>
              <li><a href="/urne" className="dropdown-link">Urne</a></li>
              <li><a href="/wahlgrab" className="dropdown-link">Wahlgrab</a></li>
              <li><a href="/friedwald" className="dropdown-link">Friedwald</a></li>
            </ul>
          </li>
          
          <li><a href="/ihre-mitwirkung" className="nav-link">Ihre Mitwirkung</a></li>
          <li><a href="/bestattungsvorsorge" className="nav-link">Bestattungsvorsorge</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
