import React from 'react';
import '../css/MainBody.css';

function MainBody() {
  return (
    <div className="main-body">
      <div className="content">
        <h2>Ich begrüße Sie herzlich und freue mich, Ihnen mein Institut vorstellen zu dürfen.</h2>
        <p>Zudem möchte ich Ihnen wissenswerte Informationen im Zusammenhang mit Trauerfällen geben.</p>
        <p>Schön, dass Sie uns besuchen!</p>
        <p className="signature">Ihr Jörg Sonnenburg</p>
        <p className="membership">Mitglied im Landesfachverband Bestattungsgewerbe Niedersachsen e.V.</p>
      </div>
    </div>
  );
}

export default MainBody;
