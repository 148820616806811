import React from 'react';

function Gallery() {
  return (
    <div className="gallery">
      <h1>Gallery</h1>
      {/* Add a grid of images here */}
    </div>
  );
}

export default Gallery;
