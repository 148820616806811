import React from 'react';

function Widerrufsformular() {
  return (
    <div>
      <h1>Widerrufsformular</h1>
      <p>This is the Widerrufsformular (Cancellation Form) page. You can update it with a form or instructions for cancellations.</p>
    </div>
  );
}

export default Widerrufsformular;
