import React from 'react';

function AGB() {
  return (
    <div>
      <h1>AGB</h1>
      <p>This is the AGB (Terms and Conditions) page. You can update it with your terms and conditions.</p>
    </div>
  );
}

export default AGB;
