import React from 'react';

function About() {
  return (
    <div className="about">
      <h1>About Nostra-Foto</h1>
      <p>Information about the photographer or company.</p>
    </div>
  );
}

export default About;
